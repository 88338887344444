.sidebar {
  background-color: #172630 !important;
}

.sidebar .nav-link {
  color: #ffffff !important;
}

.sidebar .nav-icon {
  color: #ffffff !important;
}

.sidebar .nav-link:hover {
  background-color: #173244 !important;
}

.sidebar .nav-link.active {
  background-color: #3ea9e2 !important;
}
