@charset "UTF-8";

.tableRow tr {
    background-color: white;
    border-bottom:1px solid #DDD;
    padding:.3rem;
}

.ReactTable .rt-thead.-header {
    box-shadow: none !important;
    border-radius:5px;
    border:1px solid #EEE;
}

.ReactTable {
    border:none !important;
    font-size:.75rem;
    color:#666;
}

.ReactTable .-pagination {
    box-shadow: none !important;
    margin-top:1rem;
}

.ReactTable .-pagination .-btn {
    color:white !important;
    background:#087DE4 !important;
    max-width: 150px;
    min-width:100px;
}

.ReactTable .-pagination .-next, .ReactTable .-pagination .-previous  {
    flex:0 !important;
}

.miHeader {
    border-radius:6px;
    padding:.5rem;
}

.ReactTable .rt-td {
    padding:10px !important;
    font-size:13px;
    color:#444;
    align-self: center;
    line-height:1rem;
}

.ReactTable .rt-tbody .rt-td {
    border-right:none !important;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{
    border-right:none !important;
    padding:14px !important;
}
.miTable .rt-thead .rt-th, .miTable .rt-thead .rt-td {
    background-color:#EEE;
    border:none;
    border-right:none;
    padding:10px;
    text-align: left;
    font-size:13px;
    font-weight: bold;
    color:#333;
    vertical-align: middle;
    line-height:1rem;
    box-shadow: none;
}

.miTable .rt-body .rt-td {
    border:none;
}